@import '~sass-rem';

@import 'common/styles/colors.scss';
@import 'common/styles/mixins.scss';

.container {
    width: 100%;
    max-width: calc(33% - 10px);
    padding-top: 5px;
}

.label {
    margin: 0 0 rem(4px);
}
