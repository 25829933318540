$color: black !default;
$link-color: $color !default;
$font-family: default !default;

html, body, #root {
  padding: 0;
  margin: 0;

  font-family: $font-family;
  color: $color;

  height: 100%;
}

input, textarea {
  font-family: $font-family;
  font-weight: 400;
}

/* Hide spin button for number input */
input {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}

button {
  cursor: pointer;
}

p {
  margin: 0;
}

*:focus {
  outline: none;
}

a {
  color: $link-color;
}
