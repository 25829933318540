@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.toolbar {
    background-color: $tooltip-header;
}

.container {
    padding: rem(60px) rem(80px) rem(110px);
}

.submitContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: calc(100% - 15rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: rem(20px) rem(18px);
    background-color: $white;
}

.form {
    width: 100%;
    max-width: rem(400px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: rem(50px);
    @include desktop {
        max-width: rem(520px);
        margin-right: rem(80px);
    }
}
