@import "~sass-rem";

.container {
  min-height: 100vh;
}

.content {
  padding-top: rem(60px);

  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1), width 225ms cubic-bezier(0, 0, 0.2, 1);

  &.open{
    width: calc(100% - rem(240px));
    margin-left: rem(240px);
  }
}
