@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.toolbar {
    background-color: $tooltip-header;
}

.container {
    padding: rem(10px) rem(20px);
}

.header {
    display: flex;
    flex-direction: row;
    margin-bottom: rem(20px);
    border-bottom: 1px solid lightgray;
}

.sectionHeader {
    margin-bottom: rem(20px);
    border-bottom: 1px solid lightgray;
}

.form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @include desktop {
        flex-direction: row;
    }
}

.leftWrapper {
    width: 50%;
    flex-grow: 0;
    @include desktop {
        padding-right: rem(20px);
    }
}

.rightWrapper {
    width: 50%;
    flex-grow: 0;
    padding-top: rem(20px);
    @include desktop {
        padding-top: 0;
        padding-left: rem(20px);
    }
}

.uploadButton {
    margin-right: rem(20px);
}

.customError {
    display: block;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 3px 14px 0 14px;
    color: $error;
}

.autocompleteFieldValues {
    padding-top: rem(10px);
}
.autocompleteFieldValue {
    margin: rem(5px);
}

.customSelect {
    min-width: 50%;
    margin-bottom: rem(20px);
}

.selectTextAlt {
    padding-bottom: rem(20px);
}

.audioPreview {
    margin-top: rem(20px);
}

.ruleRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(20px);
}

.sectionName {
    min-width: 200px;
    margin-right: rem(20px);
    padding: rem(10px) 0;
    flex-shrink: 0;
}

.customTextFieldWrapper {
    flex-grow: 1;
    width: calc(100% - 220px);
}

.customTextField.customTextField {
    min-width: 370px;
    width: calc(50% - 40px);
    margin: rem(5px) rem(20px) rem(5px) 0;
}

.intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: rem(10px);
    margin-bottom: rem(20px);
    border-bottom: 1px solid lightgray;
}

.adColumn {
    width: 50%;
}

.submitContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: rem(20px) 0;
}
