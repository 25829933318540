@import "~sass-rem";
// .chip {
//     :global(.MuiChip-avatar) {
//         width: unset;
//         height: unset;
//     }
// }
.chip {
    margin: 8px;
    border: 1px solid #0096ff;
    background-color: white;
    color: #0096ff;
}