@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.container {
    min-height: calc(100vh - rem(64px));
    padding: rem(60px) rem(80px);
    background-color: #eaeef3;
}

.header {
    display: flex;
    align-items: baseline;
    flex-direction: row;
    margin-bottom: rem(20px);
}

.customDatePicker {
    width: 20%;
    margin-right: rem(20px);
    background-color: white;
    cursor: pointer;
    &:hover fieldset {
        border-color: rgba(0, 0, 0, 0.87);
    }
}

.customSelect {
    max-width: 300px;
    width: 20%;
    margin-right: rem(20px);
    background-color: white;
}

.customError {
    display: block;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    padding: 3px 14px 0 14px;
    color: $error;
    background-color: #eaeef3;
}

.button {
    margin-left: auto;
    width: 15%;
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: rem(20px);
}
