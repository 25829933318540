@import '~sass-rem';
@import 'common/styles/colors.scss';
.container {
    min-height: calc(100vh - rem(64px));
    padding: rem(60px) rem(80px);
    background-color: #eaeef3;
}

.styngs {
    margin-bottom: rem(40px);
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: rem(20px);
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: rem(20px);
}

.confirmationModal {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.publishModal {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: center;
}

.publishQue {
    padding: rem(20px);
}

.header {
    width: 100%;
    font-weight: 600;
    margin-bottom: rem(18px);
    border-bottom: 1px solid lightgray;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.submitContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: rem(20px);
    border-top: 1px solid lightgray;
    justify-content: flex-end;
    padding-top: rem(18px);
    button {
        margin-left: rem(15px);
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}
