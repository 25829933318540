@import '~sass-rem';
@import 'common/styles/colors.scss';

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: rem(20px);
}

.input {
    width: calc(50% - rem(10px));
}
.text {
    padding: 15px;
}
