@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.categoriesWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .categoriesContentWrapper {
        margin-bottom: rem(20px);
    }
    .categoriesHeader {
        display: flex;
        align-items: center;
        padding-bottom: rem(10px);
        margin-bottom: rem(20px);
        border-bottom: 1px solid lightgray;
    }
    .addCategoryField {
        max-width: rem(150px);
    }
    .editCategoryField {
        max-width: rem(250px);
    }
    .categoryHeaderName {
        flex-grow: 1;
        text-align: center;
        padding: 0 rem(10px);
    }
    .addCategoryFieldWrapper {
        display: flex;
        align-items: flex-start;
    }
    .folderParent {
        display: flex;
        flex-direction: column;
        .parentItem {
            display: flex;
            padding: rem(4px) 0;

            .parentItemName {
                padding-right: rem(5px);
            }
            .parentItemStyngs {
                font-weight: 600;
            }
            .actions {
                flex-shrink: 0;
                margin-left: auto;
            }
            .deleteIcon {
                color: rgba(0, 0, 0, 0.54);
                cursor: pointer;
                margin-left: rem(5px);

                &:hover {
                    color: rgb(165, 19, 19);
                }
            }

            .editIcon {
                color: rgba(0, 0, 0, 0.54);
                cursor: pointer;
                margin-left: rem(5px);

                &:hover {
                    color: rgb(54, 54, 247);
                }
            }
        }
    }
    .folderChild {
        display: flex;
        flex-direction: column;
        .childItem {
            display: flex;
            align-items: center;
            padding: rem(2px) 0;
            span:first-child {
                width: 50%;
            }
            span:nth-child(2) {
                width: 30%;
                margin-right: auto;
            }
            .removeStyng {
                display: flex;
                flex-shrink: 0;
                border-radius: 100%;
                border: 1px solid lightgray;
                padding: rem(5px);
                margin: rem(5px);
                cursor: pointer;
                &:hover {
                    background-color: lightgray;
                }
            }
            .removeStyngIcon {
                height: rem(20px);
                width: rem(20px);
            }
        }
    }
    .regularDate {
        & .muiinputbase-root {
            pointer-events: none;
        }
        & .muiinputbase-root button {
            pointer-events: all;
        }
        & input {
            padding: rem(10px) !important;
        }
    }
    .focusDate {
        border: 1px solid red;
        & fieldset {
            border: none;
        }
        & input {
            padding: rem(10px) !important;
        }
    }
    .styngsHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(51px);
        margin-bottom: rem(20px);
        border-bottom: 1px solid lightgray;
        + .styngRow .addStyng {
            border: none;
            height: 0;
            width: rem(35px);
            margin: 0 rem(5px);
            cursor: auto;
            &:hover {
                background-color: transparent;
            }
        }
    }
    .styngRow {
        display: flex;
        align-items: center;
    }
    .addStyng {
        display: flex;
        flex-shrink: 0;
        border-radius: 100%;
        border: 1px solid lightgray;
        padding: rem(5px);
        margin: rem(5px);
        cursor: pointer;
        &:hover {
            background-color: lightgray;
        }
    }
    .addStyngIcon {
        height: rem(20px);
        width: rem(20px);
    }
    .styngItem {
        padding: 0 rem(10px);
        &:first-of-type {
            width: 60%;
        }
    }
    @include desktop {
        flex-direction: row;
        .categoriesContentWrapper {
            padding: rem(10px);
            margin-bottom: 0;
            width: 50%;
        }
    }
}
