@import "common/styles/variables.scss";

@mixin mobile-narrow {
    @media screen and (max-width: $mobile-wide-min - 1) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: $tablet-min - 1) {
        @content;
    }
}

@mixin tablet-narrow {
    @media screen and (min-width: $tablet-min) and (max-width: $tablet-wide-min - 1) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: $tablet-min) and (max-width: $desktop-min - 1) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-min) {
        @content;
    }
}

@mixin no-desktop {
    @media screen and (max-width: $desktop-min - 1) {
        @content;
    }
}

@mixin no-mobile {
    @media screen and (min-width: $tablet-min) {
        @content;
    }
}

@mixin retina {
    @media (min-device-pixel-ratio: 1.5), (min-resolution: 192dpi) {
        @content;
    }
}

@mixin webp-fallback {
    :global(.no-webp) {
        @content;
    }
}

@mixin hover {
    @media(hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }

    @media (hover: hover), (-ms-high-contrast:none) {
        &:hover {
            @content;
        }
    }

    &:active {
        @content;
    }
}

@mixin ie11-fallback {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}
