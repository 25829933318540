@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';
.container {
    padding: 21px;
}

.btnContainer {
    .buttons {
        margin-right: 10px;
    }
}

.title {
    margin-bottom: rem(20px);
    white-space: pre;
}

.id {
    margin-bottom: rem(20px);
    font-weight: normal;
    white-space: pre;
    // font-size: rem(16px);
}