@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.infoWrapper {
    display: flex;
    justify-content: space-between;
    .infoFields {
        width: calc(50% - rem(10px));
    }

    .autocompleteWrapper {
        display: flex;
        flex-direction: column;
        padding-top: rem(20px);
    }
    .autocompleteField {
        width: 500px;
    }
    .autocompleteFieldValues {
        padding: 10px 0;
    }
    .autocompleteFieldValue {
        margin: 5px;
    }

    .uploadImage {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(50% - rem(10px));
        img {
            max-width: 300px;
        }
    }
}

.submitContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99;
    width: calc(100% - 15rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: rem(20px) rem(18px);
    background-color: $white;
}

:global(.MuiPaper-root) {
    margin-bottom: 8px;
}
