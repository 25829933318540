@import '~sass-rem';
@import 'common/styles/colors.scss';
@import 'common/styles/mixins.scss';
.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 600px;
    min-height: 200px;
    background-color: #fff;
    border: 1px solid lightgray;
    box-shadow: 24px;
    border-radius: 5px;
    padding: 20px;
    .header {
        width: 100%;
        font-weight: 600;
        margin-bottom: rem(18px);
        border-bottom: 1px solid lightgray;
    }
}

.generalInfo {
    display: flex;
    justify-content: center;
    margin: 0 auto 15px;
    margin-bottom: 15px;
    align-items: center;
    font-weight: 500;
    padding: 0 25px;
    .text {
        padding-right: 10px;
    }
}

.regularInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 0 15px;
    font-weight: 500;
}

.errorInfo {
    color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 0 15px;
    font-weight: 500;
}

.errorTable {
    position: relative;
    max-height: 450px;
    overflow-y: scroll;
}

.errorTableRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: red;
    &:first-of-type {
        position: sticky;
        top: 0;
        color: black;
        background-color: #fff;
    }
}

.errorTableColumn {
    padding: 0 5px;
    border: 1px solid black;
    border-collapse: collapse;
    &:first-of-type {
        width: 30%;
    }
    &:nth-of-type(2) {
        width: 30%;
    }
    &:last-of-type {
        width: 40%;
    }
}

.submitContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid lightgray;
    padding-top: rem(18px);
    margin-top: rem(20px);
    button {
        margin-left: rem(15px);
    }
}

.uploadCover {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: rem(30px);
    > label {
        position: relative;
        z-index: 900;
        display: block;
        margin-right: rem(20px);
    }
    .fileName {
        display: inline-block;
    }
}

.uploadImage {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: rem(20px) 0 rem(12px);
    > img {
        height: 100px;
        width: 100px;
        margin-right: rem(20px);
        object-fit: cover;
    }
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.input {
    width: 100%;
}

.info {
    position: absolute;
    bottom: rem(55px);
    text-align: center;
    color: brown;
    font-weight: 500;
    width: 100%;
}

.select {
    margin-bottom: rem(15px);
}

.backdropDiv {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 1301;
}

.textArea {
    padding: rem(5px);
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 15px;
}
