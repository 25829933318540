@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.imageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadButtonWrapper {
    display: flex;
    align-items: center;
    margin-top: rem(20px);
}
