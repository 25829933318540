@import "~sass-rem";
@import "common/styles/colors.scss";
.container {
    min-height: calc(100vh - rem(64px));
    padding: rem(60px) rem(80px);
    background-color: #EAEEF3;
}

.styngs {
    margin-bottom: rem(40px);
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: rem(20px);
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: rem(10px);
    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}