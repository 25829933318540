@import '~sass-rem';
@import 'common/styles/colors.scss';
@import 'common/styles/mixins.scss';
.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 600px;
    background-color: #fff;
    border: 1px solid lightgray;
    box-shadow: 24px;
    border-radius: 5px;
    padding: 20px;
    .header {
        width: 100%;
        font-weight: 600;
        margin-bottom: rem(18px);
        border-bottom: 1px solid lightgray;
    }
    .rowWrapper {
        margin-bottom: rem(18px);
    }
    .rowContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.25rem;
        .textField {
            margin: 0;
            width: calc(50% - 25px);
        }
    }
    .deleteIcon {
        font-size: 30px;
        cursor: pointer;
        &:hover {
            color: rgb(165, 19, 19);
        }
    }
    .noPlatformWrapper {
        margin: rem(60px) 0;
        text-align: center;
    }
    .addNewwWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .textField {
            margin: 0;
            width: calc(50% - 50px);
        }
    }
}

.submitContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    border-top: 1px solid lightgray;
    padding-top: rem(18px);
    margin-top: rem(20px);
    button {
        margin-left: rem(15px);
    }
    .errorInfoBox {
        padding-bottom: rem(18px);
        width: 100%;
        flex-shrink: 0;
        text-align: center;
        color: $error;
    }
}

.uploadCover {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: rem(30px);
    > label {
        position: relative;
        z-index: 900;
        display: block;
        margin-right: rem(20px);
    }
    .fileName {
        display: inline-block;
    }
}

.uploadImage {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: rem(20px) 0 rem(12px);
    > img {
        height: 100px;
        width: 100px;
        margin-right: rem(20px);
        object-fit: cover;
    }
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.input {
    width: 100%;
}

.info {
    position: absolute;
    bottom: rem(55px);
    text-align: center;
    color: brown;
    font-weight: 500;
    width: 100%;
}

.select {
    margin-bottom: rem(30px);
}

.backdropDiv {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 51;
}

.textArea {
    padding: rem(5px);
}
