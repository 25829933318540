@import '~sass-rem';
@import 'common/styles/mixins.scss';

.filtersWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    padding: 0 0 rem(20px) 0;
    @include desktop {
        width: calc(100% + 20px);
    }
}

.filter {
    width: 100%;
    padding: rem(5px) 0 0 0;
    .label {
        margin: 0 0 rem(4px);
    }
    .customInput {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        border: 1px solid lightgray;
        input {
            height: rem(36px);
            padding: 0 rem(10px);
            border: none;
        }
        fieldset {
            padding: 0;
            border: none;
        }
    }
    .customSelect {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        border: 1px solid lightgray;
        div div {
            min-height: 30px;
            padding: 3px 8px 0 !important;
            fieldset {
                border: none !important;
            }
        }
    }
    .customAutocomplete {
        .autocompleteField {
            background-color: #fff;
        }
        :global {
            .MuiOutlinedInput-root {
                padding: 0 rem(10px);
            }
            .MuiChip-root {
                margin: rem(5px);
            }
        }
    }
    @include desktop {
        max-width: calc(25% - 20px);
        margin-right: 20px;
        &:last-of-type {
            margin-right: 0;
        }
    }
}
