@import "~sass-rem";

@import "common/styles/colors.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: row;

  color: $error;

  font-weight: 400;
  font-size: rem(14px);
  line-height: rem(20px);
}
