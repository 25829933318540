@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.autocompleteSpinnerWrapper {
    height: 90px;
    border: 1px solid lightgray;
}
.autocompleteFieldValues {
    display: flex;
    flex-wrap: wrap;
    padding-top: rem(10px);
}
.autocompleteFieldValue {
    margin: rem(5px);
}

.autocompleteWithSwitch {
    .autocompleteFieldValues {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding-top: rem(10px);
        @include desktop {
            width: 50%;
        }
    }
    .autocompleteFieldWrapper {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .autocompleteFieldValue {
        margin: rem(5px);
    }
    .autocompleteFieldSwitch {
        margin-left: auto;
    }
}
