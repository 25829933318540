@import '~sass-rem';

@import 'common/styles/colors.scss';

.container {
    margin-bottom: rem(20px);
}

.selects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: rem(20px);
    gap: rem(20px);
}

.tagStyle {
    max-width: calc(50% - 10px);
}
