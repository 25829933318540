$red: red;
$dark: black;
$dark-light: rgba(0, 0, 0, 0.87);
$gray: #8f8da6;
$gray-light: rgba(0, 0, 0, 0.6);
$gray-lines: #d4d4d9;
$gray-bg: #f6f6fc;
$white: #ffffff;
$mint: #00c3d1;
$purple: #5b50ed;
$orange: #ff9832;
$overlay: rgba(255, 255, 255, 0.95);
$white: white;
$light: #e0e0e0;
$primary: #304ffe;
$error: #d32f2f;
$tooltip-header: #1976d2;
