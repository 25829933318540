@import '~sass-rem';
@import 'common/styles/colors.scss';
.container {
    max-width: 550px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid lightgray;
    box-shadow: 24px;
    border-radius: 5px;
    padding: 15px;
    .header {
        width: 100%;
        font-weight: 600;
        max-width: rem(520px);
        border-bottom: 1px solid lightgray;
    }
}

.text {
    padding: 15px;
}

.submitContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-top: 1px solid lightgray;
    justify-content: flex-end;
    padding-top: rem(18px);
    button {
        margin-left: rem(15px);
    }
}
