@import '~sass-rem';
@import 'common/styles/colors.scss';
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: rem(8px);
    margin-bottom: rem(8px);
    > h3 {
        margin-right: rem(12px);
        line-height: rem(20px);
    }
    h4 {
        margin: rem(12px);
        line-height: rem(20px);
    }
    > h4:first-of-type {
        margin-left: auto;
    }
}

.nameHolder {
    width: 200px;
    height: auto;
    margin-right: auto;
}

.additionalInfo {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid lightgray;
}

.collapse {
    width: 100%;
}

.deleteIcon {
    cursor: pointer;
    margin-right: rem(5px);

    &:hover {
        color: rgb(165, 19, 19);
    }
}

.svgIcon {
    display: flex;
    cursor: pointer;
}
