@import "~sass-rem";

@import "common/styles/colors.scss";

.input {
    margin-top: 0;
    margin-bottom: rem(0px);

    &:not(:last-of-type) {
        margin-bottom: rem(20px);
    }
}

:global {
    .MuiTextField-root {
        .MuiOutlinedInput-input {
            font-weight: 400;
            font-size: rem(16px);
            line-height: rem(24px);
        }

        .MuiInputLabel-root.Mui-focused {
            color: $primary;
        }

        .MuiOutlinedInput-root {
            position: relative;
            padding-right: 0;

            &.MuiInputBase-adornedEnd {
                > input {
                    padding-right: rem(44px);
                }

                .MuiInputAdornment-root{
                    position: absolute;
                    right: rem(26px);
                    z-index: 10;

                    width: rem(22px);
                }
            }

            &.Mui-focused {
                > input {
                    &:-webkit-autofill {
                        background: red;
                    }
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: $primary;
                }
            }
        }
    }
}
