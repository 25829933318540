@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.sectionWrapper {
    padding-bottom: rem(100px);
}

.customSelect {
    max-width: 100%;
}

.buttonsHolder {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: rem(20px);
    background-color: #fff;
    border-top: 1px solid lightgray;
    :nth-child(1) {
        margin-right: rem(10px);
    }
}

.emptyResponse {
    width: 100%;
    padding: rem(20px);
    text-align: center;
    font-weight: 600;
}
