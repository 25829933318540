@import '~sass-rem';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - rem(64px));
    padding: rem(60px) rem(80px);
}

.title {
    padding: rem(30px) 0;
}

.logo {
    display: block;
    width: rem(350px);
    height: rem(150px);
    background-image: url('./logo.svg');
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
}
