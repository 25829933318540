@import "~sass-rem";
@import "common/styles/colors.scss";
.button {
    // height: rem(42px);
    font-weight: 500;
    font-size: rem(15px);
    line-height: rem(26px);
    box-shadow: none;
    &:global(.MuiButton-containedPrimary) {
        background-color: $primary;
        color: $white;
        & :global(.MuiLoadingButton-loadingIndicator) {
            position: relative;
            left: unset;
            color: $white;
        }
    }
}