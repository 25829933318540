@import '~sass-rem';

@import 'common/styles/colors.scss';

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: rem(8px);
    border: 1px solid $dark;
    cursor: pointer;

    > h4 {
        padding: rem(8px);
    }
}

.picture {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 40px;
    max-height: 40px;
    margin-right: 16px;
    > img {
        height: 100%;

        object-fit: cover;
    }
}

.download {
    padding: rem(8px);
    margin-left: auto;

    cursor: pointer;
}

.outlined {
    margin-left: auto;
    padding: rem(8px);

    color: rgba(0, 0, 0, 0.26);
}
