@import '~sass-rem';
@import 'common/styles/colors.scss';
.leftSide {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

.userCredentials {
    margin-right: 20px;
    margin-left: 20px;
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(32px);
    white-space: pre-line;
}

.selects {
    margin-left: 20px;
    span {
        position: absolute;
    }
    & :global(.MuiInputLabel-outlined) {
        top: -8px;
    }
    & :global(.MuiFormLabel-filled) {
        top: 0px;
    }
    & :global(.MuiSelect-select) {
        min-width: 72px;
        padding: 8px;
        background: $white;
    }
    & :global(.MuiInputLabel-root) {
        padding: 0 4px;
        background: $white;
    }
}

.header {
    z-index: 100;
    min-height: 60px;
    transition: margin 225ms cubic-bezier(0, 0, 0.2, 1), width 225ms cubic-bezier(0, 0, 0.2, 1);
    .content {
        min-height: 60px;
        background-color: black;
    }
    &.admin {
        background: #cf2200;
    }
}

.navigation {
    margin-right: 2rem;
}

.button {
    min-width: rem(104px);
    margin-left: rem(20px);
    padding: 4px 12px;
}

.logo {
    width: rem(70px);
    height: rem(28px);
    align-self: center;
    background: url('./logo.svg') no-repeat center;
    margin-top: rem(-5px);
}
