@import "~sass-rem";

@import "common/styles/colors.scss";

.container {
  min-height: calc(100vh - rem(64px)); 
  padding: rem(60px) rem(80px);

  background-color: #EAEEF3;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: rem(20px);
}
