@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 200px);
    background-color: #fff;
    border: 1px solid lightgray;
    box-shadow: 24px;
    border-radius: 5px;
    padding: 20px;

    .header {
        width: 100%;
        font-weight: 600;
        margin-bottom: rem(18px);
        border-bottom: 1px solid lightgray;

        @include desktop {
            flex-direction: row;
        }
    }

    .innerContainer {
        display: flex;
        overflow: auto;
        border-bottom: 1px solid lightgray;
        padding-bottom: rem(20px);
    }

    .searchWrapper {
        width: 100%;
        padding-right: rem(20px);
        padding-top: rem(10px);
    }

    @include desktop {
        width: calc(100% - 600px);
    }
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: rem(10px);
}

.submitContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: rem(18px);
}

.uploadCover {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: rem(20px);

    > label {
        position: relative;
        display: block;
        margin-right: rem(20px);
    }

    .fileName {
        display: inline-block;
    }
}

.uploadImage {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: rem(20px) 0 rem(12px);

    > img {
        height: 100px;
        width: 100px;
        margin-right: rem(20px);
        object-fit: cover;
    }
}

.list {
    overflow: auto;
    height: 300px;
}

.listHeader {
    // border: 1px solid $dark-light;
    border-bottom: 1px solid $dark-light;
    margin: 5px 0px 5px 0px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    :nth-child(1) {
        width: 200px;
        text-overflow: ellipsis;
    }

    :nth-child(2) {
        width: 100px;
    }

    :nth-child(3) {
        width: 100px;
    }
}

.listItem {
    border: 1px solid $gray-lines;
    border-radius: 3px;
    margin: 5px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;

    :nth-child(1) {
        width: 200px;
        text-overflow: ellipsis;
    }

    :nth-child(2) {
        width: 100px;
    }

    :nth-child(3) {
        width: 100px;
    }

    :nth-child(4) {
        text-overflow: ellipsis;
    }
}

.submitContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid lightgray;
    padding-top: rem(18px);
    button {
        margin-left: rem(15px);
    }
}

.paginationContainer {
    margin-top: rem(10px);
}
