@import '~sass-rem';
@import 'common/styles/colors.scss';

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: rem(15px);
}
