@import "~sass-rem";

@import "common/styles/colors.scss";

.container {
  display: flex;
  flex-direction: row;
  max-height: rem(100px);
  border: 1px solid $dark;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.picture {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  max-width: 100px;
  max-height: 100%;

  > img {
    height: 100%;
    width: 100%;

    object-fit: cover;
  }
}

.infoContainer {
  padding: rem(16px);

  .genreContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.timeContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: rem(24px);
}

.chips {
  margin-right: rem(8px);
}
