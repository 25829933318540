@import '~sass-rem';

.container {
    min-height: calc(100vh - rem(64px));
    padding: rem(60px) rem(80px);
    background-color: #eaeef3;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: rem(20px);
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: rem(20px);
    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        max-width: rem(344px);
    }
}
