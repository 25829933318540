@import '~sass-rem';

@import 'common/styles/colors.scss';

.container {
    width: 100%;
    .innerContainer {
        display: flex;
        position: relative;
        margin-bottom: rem(20px);
        .searchInput input {
            padding-right: rem(50px);
        }
        .searchCustomIcon {
            position: absolute;
            top: rem(12px);
            right: rem(10px);
            font-size: rem(32px);
            cursor: pointer;
            &:hover {
                color: rgb(54, 54, 247);
            }
        }
    }
    &.inModal {
        .searchResults {
            max-height: 450px;
            padding-right: rem(20px);
            overflow: auto;
        }
    }
}
