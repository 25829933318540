@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.toolbar {
    background-color: $tooltip-header;
}

.container {
    padding: rem(60px) rem(80px) rem(110px);
}

.form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .contentWrapper {
        margin-bottom: rem(20px);
    }
    @include desktop {
        flex-direction: row;
        .contentWrapper {
            padding: rem(10px);
            margin-bottom: 0;
            width: 50%;
            &:last-of-type {
                width: 100%;
            }
        }
    }
}

.submitContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99;
    width: calc(100% - 15rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: rem(20px) rem(18px);
    background-color: $white;
}
