@import "~sass-rem";

@import "common/styles/colors.scss";

.container {
  width: 100%;
  padding: rem(8px);
  margin-bottom: rem(8px);
  border: 1px solid $dark;
  cursor: pointer;
}