@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.collapseButton {
    width: 30px;
}

.image {
    max-width: 60px;
}

.deleteIcon {
    cursor: pointer;
    margin-left: rem(5px);

    &:hover {
        color: rgb(165, 19, 19);
    }
}

.editIcon {
    cursor: pointer;
    margin-left: rem(5px);

    &:hover {
        color: rgb(54, 54, 247);
    }
}

.disabledEditIcon {
    margin-left: rem(10px);
    color: none;
}

.disabledDeleteIcon {
    margin-left: rem(10px);
    color: none;
}

.nftIcon {
    margin-left: rem(10px);
    margin-top: rem(6px);
    cursor: pointer;
}

.mint {
    margin-left: rem(10px);
    margin-top: rem(1px);
    cursor: pointer;
}

.disabledIcon {
    margin-left: rem(10px);
    margin-top: rem(1px);
    opacity: 0.5;
    pointer-events: none;
}

.popover {
    position: relative;
    bottom: 10px;
}

.hidden {
    display: none;
}

.actionColumn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: (10px);
}

.addTrackIcon {
    height: rem(25px);
    width: rem(25px);
    cursor: pointer;
    border: 1px solid black;
}

.disabledAddTrackIcon {
    height: rem(25px);
    width: rem(25px);
    opacity: 0.5;
    pointer-events: none;
    border: 1px solid black;
}

.downloadTrackButton {
    flex-shrink: 0;
    height: rem(25px);
    width: rem(25px);
    margin-left: rem(5px);
    padding: rem(2px);
    cursor: pointer;
    border-radius: 100%;
    border: 1px solid black;
}

.svgIcon {
    cursor: pointer;
    padding-top: rem(5px);
}

.dmcaIcon {
    cursor: pointer;
    margin-left: rem(5px);
}
