@import "~sass-rem";

@import "common/styles/colors.scss";

.container {
  margin-bottom: rem(20px);
}

.title {
  margin-bottom: rem(8px);
}

.value {
  margin-top: 0;

  font-weight: normal;
}
