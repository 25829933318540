@import "~sass-rem";
@import "common/styles/colors.scss";
.container {
    height: 100%;
    padding: 0;
    min-width: rem(500px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(40px);
    border-radius: rem(12px);
    box-shadow: 0 0 0 1px $light;
}

.logo {
    width: rem(70px);
    height: rem(28px);
    align-self: center;
    background: url('./logo.svg') no-repeat center;
}

.button {
    width: 100%;
    margin-top: rem(32px);
}

.headerText {
    font-weight: 400;
    font-size: rem(48px);
    line-height: rem(56px);
    text-align: center;
    margin: rem(16px) 0 rem(32px);
}