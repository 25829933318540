@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 300px;
    background-color: #fff;
    border: 1px solid lightgray;
    box-shadow: 24px;
    border-radius: 5px;
    padding: 20px;

    .header {
        width: 100%;
        font-weight: 600;
        margin-bottom: rem(18px);
        border-bottom: 1px solid lightgray;
    }
}

.trackIntro {
    display: flex;
    margin-bottom: rem(10px);
    .trackCoverWrapper {
        width: 30%;
        margin-right: rem(15px);
        .trackCover {
            width: 100%;
            padding-top: 56.25%;
            background-size: cover !important;
            background-position: top center !important;
        }
    }
    .textWrapper {
        display: flex;
        flex-direction: column;
        width: auto;
        .title {
            font-weight: 600;
        }
    }
}

.selectWrapper .label {
    margin: rem(5px);
}

.downloadButton {
    margin: rem(15px) 0;
}

.spinnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 70px;
    width: calc(100% - 40px);
    margin-bottom: rem(18px);
}

.submitContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: rem(18px);
}
