@import '~sass-rem';

@import 'common/styles/colors.scss';

.container {
    margin-bottom: rem(20px);
}

.selects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: rem(20px);
    justify-content: space-between;
}

.tagStyle {
    max-width: calc(33% - 10px);
}

.selectWrapper {
    width: calc(33% - 10px);
    padding-top: 5px;
    .label {
        margin: 0 0 rem(4px);
    }
    .customSelect {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        border: 1px solid lightgray;
        div div {
            min-height: 30px;
            padding: 3px 8px 0 !important;
            fieldset {
                border: none !important;
            }
        }
    }
}
