@import '~sass-rem';

@import 'common/styles/colors.scss';

.tagsContainer {
    width: 100%;
    padding-top: 5px;
    &.errorMsg {
        .innerContainer {
            border: 1px solid red;
        }
        .errorContainer {
            color: red;
        }
    }
    .titleWrapper {
        display: flex;
        align-items: center;
    }
    .label {
        margin: 0 0 0.25rem;
    }
    .titleTooltip {
        margin: 0 0 0.25rem 0.25rem;
        font-size: 18px;
    }
    .innerContainer {
        display: flex;
        flex-wrap: wrap;
        min-height: 38px;
        margin-bottom: rem(5px);
        padding: rem(2px) rem(8px);
        background-color: #fff;
        border: 1px solid lightgray;
    }
    .chip {
        margin: rem(2px) rem(4px);
        height: 28px;
        max-width: 200px;
    }
    .tagsInput {
        flex-grow: 1;
        border: none;
    }
}
