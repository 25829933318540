@import '~sass-rem';

@import 'common/styles/colors.scss';

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: rem(20px);
    gap: rem(12px) rem(20px);
}

.selects {
    max-width: rem(200px);
}

.noLabels {
    margin: 0;
    color: $gray-light;
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(20px);
    letter-spacing: rem(0.17px);
}

.chip {
    .avatar {
        width: auto;
        margin-left: rem(10px);
        color: $dark-light;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: rem(14px);
    }
}
