@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';
.tableMsg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
}

.collapseButton {
    width: 30px;
}

.deleteIcon {
    cursor: pointer;
    &:hover {
        color: rgb(165, 19, 19);
    }
}

.editIcon {
    margin-left: rem(5px);
    margin-right: rem(15px);
    cursor: pointer;
    &:hover {
        color: rgb(54, 54, 247);
    }
}

.disabledEditIcon {
    color: none;
}

.disabledDeleteIcon {
    margin-left: rem(20px);
    color: none;
}

.hidden {
    display: none;
}