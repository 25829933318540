@import '~sass-rem';

@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.container {
    padding: 21px;
}

.btnContainer {
    .buttons {
        margin-right: 10px;
    }
}

.title {
    margin-bottom: rem(20px);
    white-space: pre;
}

// .header {
//     margin-bottom: rem(20px);
// }

// .stylglistContainer {
//     margin-bottom: rem(20px);

//     .stynglists {
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         margin-bottom: rem(20px);
//     }

//     .title {
//         margin-bottom: rem(8px);
//     }
// }

// .image {
//     width: rem(300px);
//     height: rem(200px);
//     margin-right: rem(40px);

//     @include no-desktop {
//         margin-bottom: rem(40px);
//     }

//     > img {
//         height: 100%;
//         width: 100%;

//         object-fit: cover;
//     }
// }

// .chips {
//     margin-right: rem(8px);
// }
